<template>
    <div class="modal secondary video_player" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header">
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="video_wpr" v-if="tutorialVideo">
                <iframe :src="tutorialVideo" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
            <div class="modal_body" v-else>
                <h1 class="text-center">Coming Soon</h1>
            </div>
            <div class="modal_footer">
                <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Video Tutorial',

        props: {
            modelValue: Boolean,
            tutorialVideo: {
                type: String,
                default: ''
            }
        },

        emit: [ 'update:modelValue' ],

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            }
        }
    }
</script>

<style scoped>
    .video_player .modal_body {
        width: 100%;
        height: 400px;
        display: flex;
        padding: 15px;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
</style>
